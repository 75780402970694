import React, { useMemo, useRef } from 'react';

import { PrimaryButton } from '@bloom/ui/components/PrimaryButton';
import { Tooltip } from '@bloom/ui/components/Tooltip';
import { UserPic, UserPicSizeEnum } from '@bloom/ui/components/UserPic';
import { emptyArray } from '@bloom/ui/utils/empty-value';

import { useCustomColor } from '@bloom/library/components/hooks/useCustomColor';
import { useFileUploader } from '@bloom/library/components/hooks/useFileUploader';
import { useStorageToken } from '@bloom/library/components/hooks/useTempCredentials';
import { UserIconV2 } from '@bloom/library/components/Icon/UserV2';
import { getCookie } from '@bloom/library/utils/browser';
import { isImage } from '@bloom/library/utils/image';

import { getErrorMessageFromResponse } from '../FlashMessageV2/actions';
import { AsyncStatusEnum } from '../hooks/useFetch';

interface IProps {
  avatar: string;
  className?: string;
  onError: (message: string) => void;
  onSetAvatarUrl: (url: string) => void;
  userDetails: Record<'email' | 'firstName' | 'lastName', string>;
}

const UploadUserPic: React.FC<IProps> = (props) => {
  const { avatar, className = '', onError, onSetAvatarUrl, userDetails } = props;

  const avatarInputRef = useRef<HTMLInputElement | null>(null);

  const customColor = useCustomColor();
  const token = getCookie('bloom_token');

  const { getStorageToken } = useStorageToken();

  function handleFileUploadSuccess(response: string) {
    try {
      const { file } = JSON.parse(response);

      onSetAvatarUrl(file.path);
      return Promise.resolve(file.path);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  const {
    abort,
    processing = emptyArray,
    selectFiles,
  } = useFileUploader({ destination: `review-avatar`, onSuccess: handleFileUploadSuccess });

  const [processingItem] = processing.slice(-1);

  const previewUrl = useMemo(
    () => (processingItem?.file ? URL.createObjectURL(processingItem.file) : ''),
    [processingItem?.file]
  );

  function handleRemoveClick() {
    abort();
    onSetAvatarUrl('');
  }

  function handleUploadLogoClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    avatarInputRef.current?.click();
  }

  async function handleUploadLogo(e: React.ChangeEvent<HTMLInputElement>) {
    const [file] = e.target.files || [];

    avatarInputRef.current.value = '';

    if (!token) {
      if (!userDetails.email) {
        onError('Please, provide your email');

        return;
      }

      const response = await getStorageToken({ email: userDetails.email });
      if (response.status === AsyncStatusEnum.ERROR) {
        onError(getErrorMessageFromResponse(response));

        return;
      }
    }

    if (isImage(file)) {
      selectFiles([file]);
    }

    if (avatarInputRef.current) {
      avatarInputRef.current.value = '';
    }
  }

  const avatarUrl = previewUrl || avatar;
  const isAvatarUploadDisabled = !!processingItem;

  return (
    <div className={className}>
      <div className="flex items-center justify-between gap-4">
        {avatarUrl ? (
          <UserPic
            initials={`${userDetails.firstName || ''} || ${userDetails.lastName || ''}`}
            size={UserPicSizeEnum.LARGE}
            src={avatarUrl}
          />
        ) : (
          <div className="border-mid-grey text-grey relative h-20 w-20 rounded-full border border-dashed">
            <UserIconV2 className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
          </div>
        )}
        <div className="flex flex-col gap-3">
          <input
            className="sr-only"
            name="file"
            onChange={handleUploadLogo}
            ref={avatarInputRef}
            type="file"
          />
          <Tooltip
            content="Please add an email before uploading an avatar"
            enabled={!userDetails.email}
            hoverDelay={{ close: 0, open: 100 }}
            placement="top"
          >
            <PrimaryButton
              color={customColor}
              data-testid="upload-button"
              disabled={isAvatarUploadDisabled}
              onClick={handleUploadLogoClick}
              variant="custom"
            >
              Upload
            </PrimaryButton>
          </Tooltip>

          {avatarUrl ? (
            <PrimaryButton
              data-testid="remove-button"
              onClick={handleRemoveClick}
              variant="secondary"
            >
              Remove
            </PrimaryButton>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UploadUserPic;
