__turbopack_context__.v({
  "container": "LeaveReview-module-scss-module__2Ksi4q__container",
  "ctaSection": "LeaveReview-module-scss-module__2Ksi4q__ctaSection",
  "descriptionSection": "LeaveReview-module-scss-module__2Ksi4q__descriptionSection",
  "leftColumn": "LeaveReview-module-scss-module__2Ksi4q__leftColumn",
  "overview": "LeaveReview-module-scss-module__2Ksi4q__overview",
  "quoteOverview": "LeaveReview-module-scss-module__2Ksi4q__quoteOverview",
  "rating": "LeaveReview-module-scss-module__2Ksi4q__rating",
  "ratingItem": "LeaveReview-module-scss-module__2Ksi4q__ratingItem",
  "ratingSection": "LeaveReview-module-scss-module__2Ksi4q__ratingSection",
  "responsive": "LeaveReview-module-scss-module__2Ksi4q__responsive",
  "rightColumn": "LeaveReview-module-scss-module__2Ksi4q__rightColumn",
  "signUpSection": "LeaveReview-module-scss-module__2Ksi4q__signUpSection",
  "sumbitted": "LeaveReview-module-scss-module__2Ksi4q__sumbitted",
  "uploadUserPic": "LeaveReview-module-scss-module__2Ksi4q__uploadUserPic",
});
