import React from 'react';

import { ReviewResponse } from '@bloom/codegen/models/ReviewResponse';

import { useMoment } from '@bloom/library/components/hooks/useMoment';
import ReplyIcon from '@bloom/library/components/Icon/Reply';
import { escapeHTML } from '@bloom/library/utils/string';

import style from './Comments.module.scss';

interface IProps {
  reply: ReviewResponse;
}

const Reply: React.FC<IProps> = (props) => {
  const { reply } = props;

  const { moment } = useMoment();

  return (
    <div className={style.reply}>
      <ReplyIcon className={style.replyIcon} />
      <div className={style.lead}>
        <span
          className={style.title}
          dangerouslySetInnerHTML={{
            __html: escapeHTML(
              `Response from: ${reply.clientDetails.firstName} ${reply.clientDetails.lastName}`
            ),
          }}
        />
        <span className={style.date}>{moment(reply.repliedAt).format('MMMM D, YYYY')}</span>
      </div>
      <div className={style.body} dangerouslySetInnerHTML={{ __html: escapeHTML(reply.reply) }} />
    </div>
  );
};

export default Reply;
