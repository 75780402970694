__turbopack_context__.v({
  "chart": "BarChartGroup-module-scss-module__ypzuUW__chart",
  "container": "BarChartGroup-module-scss-module__ypzuUW__container",
  "isActive": "BarChartGroup-module-scss-module__ypzuUW__isActive",
  "minimalistTemplate": "BarChartGroup-module-scss-module__ypzuUW__minimalistTemplate",
  "select": "BarChartGroup-module-scss-module__ypzuUW__select",
  "smallSize": "BarChartGroup-module-scss-module__ypzuUW__smallSize",
  "tab": "BarChartGroup-module-scss-module__ypzuUW__tab",
  "tabs": "BarChartGroup-module-scss-module__ypzuUW__tabs",
});
