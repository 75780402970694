'use client';
import React, { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { Select } from '@bloom/ui/components/Select';

import withCustomColor from '@bloom/library/components/HOC/withCustomColor';
import { useActiveTemplate } from '@bloom/library/components/hooks/useActiveTemplate';
import { reviewDescription } from '@bloom/library/components/Review/constants';

import BarChart from './BarChart';

import style from './BarChartGroup.module.scss';

interface IProps {
  charts: unknown;
  className?: string;
  customColor: string;
  isEmpty?: boolean;
  isSmall?: boolean;
  onChange: (value: string) => void;
  onLeaveReviewClick: () => void;
  photographerName: string;
}

const BarChartGroup: React.FC<IProps> = (props) => {
  const {
    className,
    customColor,
    isEmpty,
    isSmall,
    onChange,
    onLeaveReviewClick,
    photographerName,
  } = props;
  const activeTemplate = useActiveTemplate();

  const [selected, setSelected] = useState(Object.keys(reviewDescription)[0]);

  function handleChange(_: string, value: string) {
    setSelected(value);
    // A way to tell parent what options is selected.
    onChange(value);
  }

  const charts = props.charts || {};

  const ratings = Object.keys(charts);

  return (
    <div
      className={twMerge(
        style.container,
        isSmall && style.smallSize,
        className,
        activeTemplate === 'minimalist' ? style.minimalistTemplate : ''
      )}
    >
      <Select
        className={style.select}
        defaultValue={selected}
        markerColor={customColor}
        onChange={handleChange}
        options={ratings.map((rating) => ({
          label: reviewDescription[rating].title,
          value: rating,
        }))}
        value={selected || ''}
      />

      <div className={style.tabs}>
        {ratings.map((rating) => (
          <div
            className={twMerge(style.tab, rating === selected ? style.isActive : '')}
            key={reviewDescription[rating].title}
            onClick={handleChange.bind(this, '', rating)}
          >
            {reviewDescription[rating].title}
          </div>
        ))}
      </div>
      <div className={style.chart}>
        {charts[selected] ? (
          <BarChart
            activeTemplate={activeTemplate}
            isEmpty={isEmpty}
            items={charts[selected]}
            name={selected}
            onLeaveReviewClick={onLeaveReviewClick}
            photographerName={photographerName}
          />
        ) : null}
      </div>
    </div>
  );
};

export default withCustomColor(BarChartGroup);
