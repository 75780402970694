'use client';
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { escapeHTML } from '@bloom/library/utils/string';

import Bar from './Bar';

import style from './BarChart.module.scss';
import { twMerge } from 'tailwind-merge';

export default class BarChart extends Component {
  static propTypes = {
    items: PropTypes.object.isRequired,
    activeTemplate: PropTypes.string.isRequired,
    isEmpty: PropTypes.bool.isRequired,
    photographerName: PropTypes.string.isRequired,
    onLeaveReviewClick: PropTypes.func.isRequired,
  };

  render() {
    const { items, isEmpty, photographerName, onLeaveReviewClick, activeTemplate } = this.props;
    const itemsKeys = Object.keys(items).sort((a, b) => b - a);
    const total = itemsKeys.reduce((accum, key) => accum + items[key], 0);

    if (isEmpty) {
      return (
        <div
          className={twMerge(
            style.emptyState,
            activeTemplate === 'minimalist' ? style.minimalistTemplate : ''
          )}
        >
          <div className={style.emptyStateContent}>
            No reviews to show.
            <br />
            <span className={style.button} onClick={onLeaveReviewClick}>
              Click here
            </span>{' '}
            to leave a review for{' '}
            <span dangerouslySetInnerHTML={{ __html: escapeHTML(photographerName) }} />
          </div>
        </div>
      );
    }

    return itemsKeys.length ? (
      <div className={style.container}>
        <div className={style.gridlines}>
          {Array(5)
            .fill()
            .map((value, index) => (
              <div className={style.gridline} key={index}></div>
            ))}
        </div>
        <div className={style.items}>
          {itemsKeys.map((label) => (
            <Bar key={label} label={label} total={total} value={items[label]} />
          ))}
        </div>
      </div>
    ) : null;
  }
}
