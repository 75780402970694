import React from 'react';

import { twMerge } from 'tailwind-merge';

import withCustomColor from '@bloom/library/components/HOC/withCustomColor';
import { useActiveTemplate } from '@bloom/library/components/hooks/useActiveTemplate';

import style from './Bar.module.scss';

interface IProps {
  customColor?: string;
  label: string;
  total: number;
  value: number;
}

const Bar: React.FC<IProps> = (props) => {
  const { customColor, label, total, value } = props;

  const activeTemplate = useActiveTemplate();

  const percentage = total ? (value / total) * 100 : 0;

  return (
    <div
      className={twMerge(
        style.container,
        activeTemplate === 'minimalist' ? style.minimalistTemplate : ''
      )}
    >
      <span className={style.label}>{label} Star</span>
      <div className={style.bar}>
        <div
          className={style.percentage}
          style={{ backgroundColor: customColor, width: `${percentage}%` }}
        />
      </div>
      <span className={style.figure}>{Number(percentage).toFixed(0)}%</span>
    </div>
  );
};

export default withCustomColor(Bar);
