__turbopack_context__.v({
  "avatar": "Comments-module-scss-module__VRfF4G__avatar",
  "body": "Comments-module-scss-module__VRfF4G__body",
  "comment": "Comments-module-scss-module__VRfF4G__comment",
  "date": "Comments-module-scss-module__VRfF4G__date",
  "lead": "Comments-module-scss-module__VRfF4G__lead",
  "location": "Comments-module-scss-module__VRfF4G__location",
  "minimalist": "Comments-module-scss-module__VRfF4G__minimalist",
  "name": "Comments-module-scss-module__VRfF4G__name",
  "rating": "Comments-module-scss-module__VRfF4G__rating",
  "reply": "Comments-module-scss-module__VRfF4G__reply",
  "replyIcon": "Comments-module-scss-module__VRfF4G__replyIcon",
  "title": "Comments-module-scss-module__VRfF4G__title",
  "user": "Comments-module-scss-module__VRfF4G__user",
});
