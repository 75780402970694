import React, { useMemo } from 'react';

import merge from 'deepmerge';
import { Form } from 'formik';
import { twMerge } from 'tailwind-merge';
import * as yup from 'yup';

import { PublicProfileSimpleUserResponse } from '@bloom/codegen/models/PublicProfileSimpleUserResponse';

import { useToggle } from '@bloom/ui/components/hooks/useToggle';
import { Input } from '@bloom/ui/components/Input';
import { Modal, ModalSizeEnum } from '@bloom/ui/components/Modal';
import { PrimaryButton } from '@bloom/ui/components/PrimaryButton';
import { Textarea } from '@bloom/ui/components/Textarea';

import { useInfoMessage } from '@bloom/library/components/FlashMessageV2/info-message-context';
import { FormState } from '@bloom/library/components/Form/FormState';
import { useCustomColor } from '@bloom/library/components/hooks/useCustomColor';
import { AsyncStatusEnum, useFetch } from '@bloom/library/components/hooks/useFetch';
import { useMe } from '@bloom/library/components/hooks/useMe';
import { usePublicAccountByCustomUrl } from '@bloom/library/components/hooks/usePublicAccount';
import { reviewDescription } from '@bloom/library/components/Review/constants';
import RatingOverview from '@bloom/library/components/Review/RatingOverview';
import UploadUserPic from '@bloom/library/components/Upload/UploadUserPic';
import { getCookie } from '@bloom/library/utils/browser';

import style from './LeaveReview.module.scss';

interface IProps {
  onClose: VoidFunction;
  open: boolean;
  photographer: PublicProfileSimpleUserResponse;
}

interface IFormValues {
  attentiveness: number;
  avatar: string;
  comment: string;
  communication: number;
  email: string;
  firstName: string;
  headline: string;
  lastName: string;
  location: string;
  overallValue: number;
  processingTime: number;
  quality: number;
}

const validationSchema = yup.object({
  attentiveness: yup
    .number()
    .min(1, 'Rating should be from 1 to 5')
    .max(5, 'Rating should be from 1 to 5')
    .required(),
  comment: yup
    .string()
    .min(3, 'The comment must be at least 3 characters.')
    .max(65000, 'The comment should not be more then 65000 characters.')
    .required(),
  communication: yup
    .number()
    .min(1, 'Rating should be from 1 to 5')
    .max(5, 'Rating should be from 1 to 5')
    .required(),
  email: yup.string().email().required(),
  firstName: yup.string().required(),
  headline: yup
    .string()
    .min(3, 'The headline must be at least 3 characters.')
    .max(255, 'The headline should not be more than 255 characters.')
    .required(),
  lastName: yup.string().required(),
  overallValue: yup
    .number()
    .min(1, 'Rating should be from 1 to 5')
    .max(5, 'Rating should be from 1 to 5')
    .required(),
  processingTime: yup
    .number()
    .min(1, 'Rating should be from 1 to 5')
    .max(5, 'Rating should be from 1 to 5')
    .required(),
  quality: yup
    .number()
    .min(1, 'Rating should be from 1 to 5')
    .max(5, 'Rating should be from 1 to 5')
    .required(),
});

const LeaveReview: React.FC<IProps> = (props) => {
  const { onClose, open } = props;

  const { publicAccount } = usePublicAccountByCustomUrl();

  const { showErrorMessage, showErrorMessageFromResponse } = useInfoMessage();

  const { me } = useMe();

  const { post: leaveReview, status } = useFetch();

  const user = useMemo(
    () =>
      merge(
        {
          avatar: '',
          email: '',
          firstName: '',
          lastName: '',
          primaryAddress: { city: '', state: '' },
        },
        me || {}
      ),
    [me]
  );

  const isLoggedIn = !!getCookie('bloom_token');

  const [isReviewSubmitted, { setTrue }] = useToggle();

  const customColor = useCustomColor();

  const initialValues: IFormValues = useMemo(() => {
    const location =
      user.primaryAddress?.city && user.primaryAddress?.state
        ? `${user.primaryAddress.city} ${user.primaryAddress.state}`
        : '';

    const stars = {
      attentiveness: 0,
      communication: 0,
      overallValue: 0,
      processingTime: 0,
      quality: 0,
    };

    return {
      avatar: user.avatar || '',
      comment: '',
      email: user.email || '',
      firstName: user.firstName || '',
      headline: '',
      lastName: user.lastName || '',
      location,
      ...stars,
    };
  }, [user]);

  function handleSubmit(values: IFormValues) {
    const { avatar, comment, email, firstName, headline, lastName, location, ...stars } = values;

    const review = {
      clientDetails: {
        avatar,
        email,
        firstName,
        lastName,
      },
      comment,
      headline,
      location,
      stars,
    };

    const photographerId = publicAccount?.user.id;

    leaveReview(`/api/users/${photographerId}/reviews`, review).then((res) => {
      if (res.status === AsyncStatusEnum.SUCCESS) {
        // if review is saved than show confirmation layout
        setTrue();
      }

      if (res.status === AsyncStatusEnum.ERROR) {
        showErrorMessageFromResponse(res);
      }
    });
  }

  function handleUploadError(errorMsg: string) {
    showErrorMessage(errorMsg);
  }

  const stars = ['overallValue', 'quality', 'attentiveness', 'processingTime', 'communication'];
  const photographerName =
    `${publicAccount?.user.firstName || ''} ${publicAccount?.user.lastName || ''}`.trim();

  return (
    <Modal
      className="p-0"
      onClose={onClose}
      open={open}
      size={isReviewSubmitted ? ModalSizeEnum.SMALL : ModalSizeEnum.LARGE}
    >
      <div
        className={twMerge(style.container, style.responsive, isReviewSubmitted ? 'p-12' : '')}
        style={{ borderColor: customColor }}
      >
        <FormState<IFormValues>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, handleChange, setFieldValue, values }) => {
            function handleRatingChange(e: React.MouseEvent<HTMLElement>, key: string) {
              e.preventDefault();

              const element = e.currentTarget;
              const parent = e.currentTarget.parentNode;
              const index = Array.prototype.indexOf.call(parent.children, element) + 1;

              setFieldValue(key, index);
            }

            function onSetAvatarUrl(avatarUrl: string) {
              setFieldValue('avatar', avatarUrl);
            }

            return isReviewSubmitted ? (
              <div className={style.sumbitted}>
                <h1>Review Submitted</h1>
                <p>
                  Thank you for taking the time to review my work. I trust we will continue to work
                  together in the future.
                </p>
                <PrimaryButton data-testid="home-button" onClick={onClose}>
                  Home
                </PrimaryButton>
              </div>
            ) : (
              <Form>
                <section className={style.overview}>
                  <h1>Leave a Review</h1>
                  <p>
                    All local businesses depend on credible reviews from their clients, please take
                    a moment to let others know about your experience with {photographerName}
                  </p>
                </section>
                <section>
                  <h3>Rate each category</h3>
                  <div className={twMerge('md:flex', style.ratingSection)}>
                    <div className={twMerge('flex-1', style.leftColumn)}>
                      {stars.slice(0, 3).map((key) => (
                        <RatingOverview
                          key={key}
                          {...reviewDescription[key]}
                          isUsesLabel
                          onRatingChange={(e) => handleRatingChange(e, key)}
                          starCount={values[key] || 0}
                        />
                      ))}
                    </div>
                    <div className={twMerge('flex-1', style.rightColumn)}>
                      {stars.slice(3, 5).map((key) => (
                        <RatingOverview
                          key={key}
                          {...reviewDescription[key]}
                          isUsesLabel
                          onRatingChange={(e) => handleRatingChange(e, key)}
                          starCount={values[key] || 0}
                        />
                      ))}
                    </div>
                  </div>
                </section>
                <section className={style.descriptionSection}>
                  <h3>Write your review</h3>
                  <Input
                    data-testid="headline-input"
                    invalid={!!errors.headline}
                    name="headline"
                    onChange={handleChange}
                    placeholder="Enter Headline"
                    value={values.headline}
                  />
                  <Textarea
                    className="mt-6"
                    data-testid="comment-input"
                    invalid={!!errors.comment}
                    name="comment"
                    onChange={handleChange}
                    placeholder="Describe your experience"
                    value={values.comment}
                  />
                </section>
                {!isLoggedIn && (
                  <section className={style.signUpSection}>
                    <div className="flex flex-col gap-6">
                      <h3>Please briefly introduce yourself</h3>
                      <Input
                        data-testid="first-name-input"
                        invalid={!!errors.firstName}
                        name="firstName"
                        onChange={handleChange}
                        placeholder="First Name"
                        value={values.firstName}
                      />
                      <Input
                        data-testid="last-name-input"
                        invalid={!!errors.lastName}
                        name="lastName"
                        onChange={handleChange}
                        placeholder="Last Name"
                        value={values.lastName}
                      />
                      <Input
                        data-testid="location-input"
                        invalid={!!errors.location}
                        name="location"
                        onChange={handleChange}
                        placeholder="Location"
                        value={values.location}
                      />
                      <Input
                        data-testid="email-input"
                        invalid={!!errors.email}
                        name="email"
                        onChange={handleChange}
                        placeholder="Email"
                        type="email"
                        value={values.email}
                      />
                    </div>
                    <div>
                      <h3>Avatar (optional)</h3>
                      <UploadUserPic
                        avatar={values.avatar}
                        className={style.uploadUserPic}
                        onError={handleUploadError}
                        onSetAvatarUrl={onSetAvatarUrl}
                        userDetails={{
                          email: values.email,
                          firstName: values.firstName,
                          lastName: values.lastName,
                        }}
                      />
                    </div>
                  </section>
                )}
                <section className={style.ctaSection}>
                  <PrimaryButton
                    color={customColor}
                    data-testid="submit-button"
                    loading={status === AsyncStatusEnum.PENDING}
                    type="submit"
                    variant="custom"
                  >
                    Submit
                  </PrimaryButton>
                  <PrimaryButton data-testid="cancel-button" onClick={onClose} variant="secondary">
                    Cancel
                  </PrimaryButton>
                </section>
              </Form>
            );
          }}
        </FormState>
      </div>
    </Modal>
  );
};

export default LeaveReview;
