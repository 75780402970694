import { twMerge } from 'tailwind-merge';

import { UserPic } from '@bloom/ui/components/UserPic';

import { useActiveTemplate } from '@bloom/library/components/hooks/useActiveTemplate';
import { escapeHTML } from '@bloom/library/utils/string';

import style from './Comments.module.scss';

const User: React.FC = (props) => {
  const { location, user } = props;
  const { firstName, lastName } = user;

  const activeTemplate = useActiveTemplate();

  return (
    <div className={twMerge(style.user, activeTemplate === 'minimalist' ? style.minimalist : '')}>
      <UserPic className={style.avatar} initials={`${user.firstName} ${user.lastName}`} size="md" />
      <span
        className={style.name}
        dangerouslySetInnerHTML={{
          __html: escapeHTML(firstName + ' ' + lastName.slice(0, 1) + '.'),
        }}
      />
      <span className={style.location}>{location}</span>
    </div>
  );
};

export default User;
