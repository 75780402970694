__turbopack_context__.v({
  "button": "BarChart-module-scss-module__lQh_iq__button",
  "container": "BarChart-module-scss-module__lQh_iq__container",
  "emptyState": "BarChart-module-scss-module__lQh_iq__emptyState",
  "emptyStateContent": "BarChart-module-scss-module__lQh_iq__emptyStateContent",
  "gridline": "BarChart-module-scss-module__lQh_iq__gridline",
  "gridlines": "BarChart-module-scss-module__lQh_iq__gridlines",
  "items": "BarChart-module-scss-module__lQh_iq__items",
  "minimalistTemplate": "BarChart-module-scss-module__lQh_iq__minimalistTemplate",
});
