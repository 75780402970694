export const reviewDescription = {
  overallValue: {
    title: 'Overall Value',
    desc: 'How would you rate the overall experience and value?',
  },
  quality: {
    title: 'Quality',
    desc: 'Did the quality match your expectations?',
  },
  attentiveness: {
    title: 'Professionalism',
    desc: 'How respectable, attentive, and competent was your service?',
  },
  processingTime: {
    title: 'Timing',
    desc: 'Was your service provided on time?',
  },
  communication: {
    title: 'Communication',
    desc: 'Were you able to freely communicate and have your questions answered?',
  },
};
