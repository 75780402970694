import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Rating from '@bloom/library/components/Widget/Rating';

import commonStyle from './LeaveReview.module.scss';

export default class RatingOverview extends Component {
  static propTypes = {
    onRatingChange: PropTypes.func,
    starCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    isUsesLabel: PropTypes.bool,
  };

  static defaultProps = {
    onRatingChange: () => {},
    isUsesLabel: false,
  };

  render() {
    const { onRatingChange, starCount, title, desc, isUsesLabel } = this.props;

    return (
      <div className={commonStyle.ratingItem}>
        <h4>{title}</h4>
        <p>{desc}</p>
        <Rating
          className={commonStyle.rating}
          // starCount is required prop, but it is undefined
          // until component did mount
          isShowCount={false}
          isUsesLabel={isUsesLabel}
          onRatingChange={onRatingChange}
          starCount={starCount}
        />
      </div>
    );
  }
}
