import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

import BarChartGroup from '@bloom/library/components/BarChart/BarChartGroup';
import Comments from '@bloom/library/components/Comments/Comments';
import withCustomColor from '@bloom/library/components/HOC/withCustomColor';
import withTemplate from '@bloom/library/components/HOC/withTemplate';
import Rating from '@bloom/library/components/Widget/Rating';
import { escapeHTML } from '@bloom/library/utils/string';

import { reviewDescription as categories } from './constants';

import style from './Reviews.module.scss';

class Reviews extends Component {
  static propTypes = {
    // Make these props optional in order to mute
    // the error caused by RouterContext cloning
    // this component without providing appropriate props.
    isCommentsShown: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired,
    activeTemplate: PropTypes.string.isRequired,
    customColor: PropTypes.string.isRequired,
    photographer: PropTypes.string,
    className: PropTypes.string,
    onCommentsShow: PropTypes.func.isRequired,
    onCommentsHide: PropTypes.func.isRequired,
    onLeaveReviewClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
    photographer: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      category: 'overallValue',
      // TODO delete
      commentCount: 10,
    };
  }

  // TODO refactor
  handleLoadClick = (e) => {
    e.preventDefault();
    const { reviews } = this.props.profile;
    const { commentCount } = this.state;
    if (reviews[commentCount]) {
      this.setState({ commentCount: commentCount + 10 });
    }
  };

  handleCategorySelect = (category) => {
    this.setState({ category });
  };

  render() {
    const { activeTemplate, customColor, photographer, className, profile, isCommentsShown } =
      this.props;
    const { commentCount, category } = this.state;
    const {
      user: { firstName, lastName },
      homepageName,
    } = this.props.profile;
    const photographerName = homepageName || `${firstName} ${lastName}`;
    let headerText = photographerName;
    if (profile.reviewSummary.reviewCount > 0) {
      headerText += ` has ${profile.reviewSummary.reviewCount} reviews`;
    }

    // Show the most recent reviews first.
    const comments = profile.reviews
      .slice()
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    return (
      <section
        className={twMerge(
          style.content,
          isCommentsShown ? style.expanded : '',
          comments.length === 0 ? style.noComments : '',
          activeTemplate === 'minimalist' ? style.minimalistTemplate : '',
          activeTemplate === 'vertical-split' ? style.verticalSplitTemplate : '',
          activeTemplate === 'nordic-air' ? style.nordicAirTemplate : '',
          className
        )}
      >
        <div className={style.reviewsOverview}>
          <Rating
            className="pt-0.5"
            reviewCount={profile.reviewSummary.reviewCount}
            starCount={parseFloat(profile.reviewSummary.average) || 5}
          />
        </div>
        {photographer && <h1 dangerouslySetInnerHTML={{ __html: escapeHTML(headerText) }} />}
        <p className={style.description}>
          <span className={style.semibold}>{categories[category]?.title}</span> -{' '}
          {categories[category].desc}
        </p>
        <BarChartGroup
          charts={profile.reviewSummary.breakdown}
          className={style.chart}
          isEmpty={profile.reviewSummary.reviewCount === 0}
          onChange={this.handleCategorySelect}
          onLeaveReviewClick={this.props.onLeaveReviewClick}
          photographerName={photographerName}
        />
        <div>
          <BarChartGroup
            charts={profile.reviewSummary.breakdown}
            className={style.smallChart}
            isEmpty={profile.reviewSummary.reviewCount === 0}
            isSmall
            onChange={this.handleCategorySelect}
            onLeaveReviewClick={this.props.onLeaveReviewClick}
            photographerName={photographerName}
          />
        </div>
        {comments.length > 0 && (
          <a
            className={twMerge(style.expandButton, isCommentsShown ? style.hiddenButton : '')}
            href="#"
            onClick={this.props.onCommentsShow}
          >
            Expand Comments
            <i style={{ borderColor: customColor }} />
          </a>
        )}
        {comments.length > 0 && isCommentsShown ? (
          <div className={style.comments}>
            <Comments comments={comments.slice(0, commentCount)} />
            <div className={style.commentsButtons}>
              <a className={style.hideButton} href="#" onClick={this.props.onCommentsHide}>
                Hide Comments
                <i style={{ borderColor: customColor }} />
              </a>
              {comments[commentCount] ? (
                <a className={style.loadButton} href="#" onClick={this.handleLoadClick}>
                  Load More
                </a>
              ) : null}
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}

export default withTemplate(withCustomColor(Reviews));
