import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import Comment from './Comment';
import style from './Comments.module.scss';

export default class Comments extends Component {
  static propTypes = {
    className: PropTypes.string,
    comments: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  render() {
    const { className, comments } = this.props;

    return (
      <div className={twMerge(style.comments, className)}>
        {comments.map((props) => (
          <Comment {...props} key={props.id} />
        ))}
      </div>
    );
  }
}
