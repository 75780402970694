__turbopack_context__.v({
  "chart": "Reviews-module-scss-module__rIKMiq__chart",
  "comments": "Reviews-module-scss-module__rIKMiq__comments",
  "commentsButtons": "Reviews-module-scss-module__rIKMiq__commentsButtons",
  "content": "Reviews-module-scss-module__rIKMiq__content",
  "description": "Reviews-module-scss-module__rIKMiq__description",
  "expandButton": "Reviews-module-scss-module__rIKMiq__expandButton",
  "hiddenButton": "Reviews-module-scss-module__rIKMiq__hiddenButton",
  "hideButton": "Reviews-module-scss-module__rIKMiq__hideButton",
  "loadButton": "Reviews-module-scss-module__rIKMiq__loadButton",
  "minimalistTemplate": "Reviews-module-scss-module__rIKMiq__minimalistTemplate",
  "noComments": "Reviews-module-scss-module__rIKMiq__noComments",
  "nordicAirTemplate": "Reviews-module-scss-module__rIKMiq__nordicAirTemplate",
  "ratingWidget": "Reviews-module-scss-module__rIKMiq__ratingWidget",
  "reviewsOverview": "Reviews-module-scss-module__rIKMiq__reviewsOverview",
  "semibold": "Reviews-module-scss-module__rIKMiq__semibold",
  "smallChart": "Reviews-module-scss-module__rIKMiq__smallChart",
  "verticalSplitTemplate": "Reviews-module-scss-module__rIKMiq__verticalSplitTemplate",
  "wrapper": "Reviews-module-scss-module__rIKMiq__wrapper",
});
