import React from 'react';

import { useMoment } from '@bloom/library/components/hooks/useMoment';
import Rating from '@bloom/library/components/Widget/Rating';
import { escapeHTML } from '@bloom/library/utils/string';

import Reply from './Reply';
import User from './User';

import style from './Comments.module.scss';

interface IProps {
  // Note, in API response average is a string.
  average: string | number;
  clientDetails: unknown;
  comment: string;
  createdAt: string;
  headline: string;
  location: string;
  repliedAt: string;
  reply: string;
}

const Comment: React.FC<IProps> = (props) => {
  const { average, clientDetails, comment, createdAt, headline, location, repliedAt, reply } =
    props;

  const { moment } = useMoment();

  return (
    <div className={style.comment}>
      <User location={location} user={clientDetails} />
      <div className={style.content}>
        <Rating className={style.rating} isShowCount={false} starCount={parseFloat(average)} />
        <div className={style.lead}>
          <span
            className={style.title}
            dangerouslySetInnerHTML={{ __html: escapeHTML(headline) }}
          />
          <span className={style.date}>{moment(createdAt).format('MMMM D, YYYY')}</span>
        </div>
        <div className={style.body} dangerouslySetInnerHTML={{ __html: escapeHTML(comment) }} />
        {reply ? <Reply reply={{ reply, repliedAt }} /> : null}
      </div>
    </div>
  );
};

export default Comment;
